a{
  text-decoration: none;
  color: inherit;
}

.matrix {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    background-color: rgb(233, 233, 233);

    .popup {
      width: 700px;
      height: auto;
      background: white;
      position: absolute;
      z-index: 999999!important;
      border-radius: 5px;
      padding: 15px 15px;
      display: flex;
      flex-direction: column;
      
      p {
        font-weight: 400;
        text-align: start;
      }

      .iconWrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 0 7px;
        .icon {
          width: 17px;
          height: 17px;
          margin-bottom: 10px;
  
          &:hover {
            cursor: pointer;
            color: darken($color: #93CC58, $amount: 10%)
          }
  
        }

      }

    }

    .topBar {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;

      .leftWrapper {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .icon {
          color: #93CC58;
          width: 30px;
          height: auto;
          &:hover {
            cursor: pointer;
            color: darken($color: #93CC58, $amount: 10%)
          }
        }
      }



      .usernames {
        z-index: 100;
      }

      .roomId {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        height: 100%;
        max-width: 150px;
        padding: 10px;
        font-size: 20px;
        border-radius: 5px;
        background: #93CC58;
        margin-bottom: 5px;
        margin-top: 5px;
        color: white;
        margin: 5px 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          cursor: pointer;
        }

      }

    }

    ::placeholder {
      color: #93CC58;
    }

    .text {
      display: flex;
      justify-content: center;
      width: 500px;
      background: #131919;
      border-radius: 5px;
      height: 180px;

      h1 {
        color: white;
      }

      input {
        border: none;
        border-radius: 5px;
        background: #93CC58;
        color: #93CC58;
        padding: 5px 10px;
        font-weight: 800;
        height: 40px;
        width: 192px;
        font-size: 20px;
        margin: 5px 0;
        text-align: center;
      }

      ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #93CC58;
        opacity: 1; /* Firefox */
        font-weight: 800;
      }

    }

    .buttons {
      display: flex;
      flex-direction: row;
      width: 500px;
      justify-content: space-between;

      h1 {
        color: white;
      }

      .leftButtons {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #131919;
      border-radius: 5px;
      height: 180px;
      width: 240px;

        .roomId {
          font-weight: 800;
          height: 50px;
          width: 212px;
          font-size: 20px;
          border-radius: 5px;
          background: #93CC58;
          margin-bottom: 5px;
          margin-top: 5px;


          &:hover {
            cursor: pointer;
          }
        }

      }

      .rightButtons {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: lighten(#131919, 10%);
        border-radius: 5px;
        height: 180px;
        width: 240px;

        button {
          border: none;
          background-color: #93CC58;
          color: white;
          border-radius: 5px;
          font-weight: 800;
          padding: 5px 10px;

          &:hover {
            cursor: pointer;
          }
        }

      }

      input {
        text-align: center;
        border: none;
        border-radius: 5px;
        background: #93CC58;
        color: white;
        padding: 5px 10px;
        font-weight: 800;
        height: 40px;
        width: 192px;
        font-size: 20px;
        margin: 5px 0;
      }
  
    }

    .centerWrapper {
      display: flex;
      position: relative;
      max-width: 740px;
      .cardDeck{
        max-width: 560px;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      .resultWrapper {
        z-index: 8;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 5px;

        .result {
          width: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          padding: 0 10px;
          height: 40px;
          color: white;
          right: -280px;
          top: 10px;
          margin: 5px 0;
          font-weight: 800;
          position: relative;
          left: 0;
          
          .sips {
            position: absolute;
            height: 40px;
            background: #93CC58;
            border-radius: 0 5px 5px 0;
            right: 0;
            width: 55px;
            display: flex;
            justify-content: space-around;
            align-items: center;
          }

        }

      }

    }

  

    .footer {
      position: absolute;
      display: flex;
      width: 100%;
      height: 3rem;
      justify-content: center;
      align-items: center;
      bottom: 0;
      background: #131919;
      color: white;
        .footerImage {
            width: auto;
            height: 30px;
            margin: 0 5px 0 0;
        }
    }

  }
  
