.wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #131919;
    color: white;

    .logo {
        margin-top: -10rem;
        max-width: 60%;
        background: red;
    }
    
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;

        .button {
            width: 130px;
            height: 40px;
            border-radius: 5px;
            background: #93CC58;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 25px;
            color: white;

            &:hover {
                cursor: pointer;
            }
        }

      }

      .footer {
        position: absolute;
        display: flex;
        width: 100%;
        height: 3rem;
        justify-content: center;
        align-items: center;
        bottom: 0;
        background: #131919;
        color: white;
          .footerImage {
              width: auto;
              height: 30px;
              margin: 0 5px 0 0;
          }
      }

}

  
