.app {
  text-align: center;
  overflow: hidden;
}


textarea:focus, input:focus{
  outline: none;
}

*:focus {
  outline: none;
}
