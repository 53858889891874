.card {
    width: 50px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    border-radius: 5px;
    border: 4px solid;
    box-shadow: -16px -5px 12px -9px rgba(0,0,0,0.1);
    position: relative;
    background-color: whitesmoke;

    &:hover {
        cursor: pointer;
    }

    .insideCard {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .topInside {
            display: flex;
            justify-content: flex-start;
            margin-left: 3px;
            
        }
        
        .middleInside {
            height: calc(75px-36px);
        }
        
        .bottomInside {
            transform: rotate(180deg);
            margin-right: 3px;
            display: flex;
            justify-content: flex-start;
            
        }

    }

}

@media only screen and (max-width: 760px) {
    .card {
        width: 25px;
        height: 38px;

        p {
            font-size: 8px;
        }

        .insideCard {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 5px 0;
    
            .topInside {
                height: auto;
                margin-left: 5px;
            }
            
            .middleInside {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            
            .bottomInside {
                transform: rotate(180deg);
                margin-right: 5px;
            }
    
        }
    }
  }


.popup {
    z-index: 99999!important;
    padding: 5px;
    width: 300px;
    height: 100px;
    background: white;
    border-radius: 5px;
    display: flex;
    box-shadow: -5px 10px 14px 8px rgba(0,0,0,0.17);
    position: absolute;
    flex-direction: column;
    justify-content: space-around;
    z-index: 8;
    top: -15px;
    left: 55px;

    .popupTop {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
    }
    
    .popupContent {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    
    .arrowDown {
        width: 0; 
        height: 0; 
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent; 
        position: absolute;
        left: -10px;
        bottom: 35px;
        border-right:10px solid white; 
      }
}

.button {
    background: #131919;
    padding: 10px 20px;
    border: none;
    color: white;
    font-weight: 800;
    border-radius: 5px;
    margin: 5px 0;
    &:hover {
        background: lighten(#131919, 10%);
        cursor: pointer;
    }
}

p {
    margin: 0;
    font-weight: 800;
}

.backImage {
    width: 45px;
    height: auto;
}

@media only screen and (max-width: 760px) {
    .backImage {
        width: 20px;
        height: auto;
    }
}

.symbol {
    width: 35px;
    height: auto;
}
@media only screen and (max-width: 760px) {
    .symbol {
        width: 20px;
        height: auto;
    }
}
